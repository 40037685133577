.buttonSpace {
    margin-left: 0px;
    margin-right: 80px;
    background-color: #cccccc;
}

.days {
    display: flex;
}

.materialTable {
    background-color: lightgrey;
}

MaterialTable {
    background-color: #f44336;
}

.newRuleList {
    margin-left: 30px;
    margin-bottom: 15px;
    margin-top: 2px;
    justifyContent: "flex-end";
    alignItems: "center";
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root{
    background-color: #4caf50 !important;
}

